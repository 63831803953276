import React from "react"
import * as Chakra from "@chakra-ui/react"
import { PageProps } from "gatsby"
import { Seo } from "../components/seo"

const NotFoundPage = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Flex
    alignItems="center"
    justifyContent="center"
    flexGrow={1}
    width="100%"
  >
    <Seo title="404" location={location} />
    <Chakra.Box color="#fff">404</Chakra.Box>
  </Chakra.Flex>
)

export default NotFoundPage
